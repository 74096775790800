/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    strong: "strong",
    hr: "hr",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "status-variations",
    style: {
      position: "relative"
    }
  }, "Status Variations", React.createElement(_components.a, {
    href: "#status-variations",
    "aria-label": "status variations permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Banner have 4 possible statuses: info (default), success, warning, and critical. An optional icon can also be affixed for each status."), "\n", React.createElement(_components.h2, {
    id: "info-default",
    style: {
      position: "relative"
    }
  }, "Info (Default)", React.createElement(_components.a, {
    href: "#info-default",
    "aria-label": "info default permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Use for general, non-critical information."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Banner\n\t\ticon\n\t\ttitle=\"You are viewing an Inactive Item.\"\n\t/>\n")), "\n", React.createElement(_components.h2, {
    id: "success",
    style: {
      position: "relative"
    }
  }, "Success", React.createElement(_components.a, {
    href: "#success",
    "aria-label": "success permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For giving the user detailed feedback. Use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/snackbar/"
  }, "Snackbar")), " notification for simple success messages."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Banner\n\t\tstatus=\"success\"\n\t\ticon\n\t\ttitle=\"Report sent.\"\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"View Report\"\n\t\tonSecondaryActionClick={() => {}}\n\t\tsecondaryActionName=\"New Report\"\n\t>\n\t\tYour report 'S1234' was sent over to Jane Doe.\n\t</Banner>\n")), "\n", React.createElement(_components.h2, {
    id: "warning",
    style: {
      position: "relative"
    }
  }, "Warning", React.createElement(_components.a, {
    href: "#warning",
    "aria-label": "warning permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For information or feedback that needs attention."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Banner\n\t\tstatus=\"warning\"\n\t\ticon\n\t\ttitle=\"This report is too large to send.\"\n\t/>\n")), "\n", React.createElement(_components.h2, {
    id: "critical",
    style: {
      position: "relative"
    }
  }, "Critical", React.createElement(_components.a, {
    href: "#critical",
    "aria-label": "critical permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When something has gone wrong and action is required."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Banner\n\t\tstatus=\"critical\"\n\t\ticon\n\t\ttitle=\"Incorrect password.\"\n\t/>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "close-action",
    style: {
      position: "relative"
    }
  }, "Close Action", React.createElement(_components.a, {
    href: "#close-action",
    "aria-label": "close action permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In general, banners should be dismissible. Important, persistent events are the general exception."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Banner\n\t\tonClose={() => {}}\n\t\ttitle=\"You are closing a banner.\"\n\t/>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "buttons",
    style: {
      position: "relative"
    }
  }, "Buttons", React.createElement(_components.a, {
    href: "#buttons",
    "aria-label": "buttons permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "1 or 2 actions can be added to each banner. When there is no body text, buttons will appear on the right, otherwise they go below the body text. The button styling cannot be changed."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Stack direction=\"column\" spacing=\"3\">\n\t\t<Banner\n\t\t\tonPrimaryActionClick={() => {}}\n\t\t\tprimaryActionName=\"Activate Item\"\n\t\t\ttitle=\"You are viewing an inactive item.\"\n\t\t/>\n\t\t<Banner\n\t\t\tonPrimaryActionClick={() => {}}\n\t\t\tprimaryActionName=\"Activate Item\"\n\t\t\tonSecondaryActionClick={() => {}}\n\t\t\tsecondaryActionName=\"Manage Items\"\n\t\t\ttitle=\"You are viewing an inactive item.\"\n\t\t/>\n\t\t<Banner\n\t\t\tonPrimaryActionClick={() => {}}\n\t\t\tprimaryActionName=\"Activate Item\"\n\t\t\tonSecondaryActionClick={() => {}}\n\t\t\tsecondaryActionName=\"Manage Items\"\n\t\t\ttitle=\"You are viewing in inactive item.\"\n\t\t>\n\t\t\tChanges cannot be made until you activate the item.\n\t\t</Banner>\n\t</Stack>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "body-text",
    style: {
      position: "relative"
    }
  }, "Body Text", React.createElement(_components.a, {
    href: "#body-text",
    "aria-label": "body text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In addition to a message title, optional copy can be provided. Copy should be inside ", React.createElement(_components.code, null, "<p>"), ", ", React.createElement(_components.code, null, "<ol>"), ", or ", React.createElement(_components.code, null, "<ul>"), " HTML tags if doing more than a single line. Buttons are not added in this area."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Stack direction=\"column\" spacing=\"3\">\n\t\t<Banner title=\"Select a table\">\n\t\t\t<p>This is a pretty big notification for something of some significance. Here is an even longer message that is sure to reach a second line if you believe in yourself.</p>\n\t\t</Banner>\n\t\t<Banner title=\"Select a table\">\n\t\t\t<ul>\n\t\t\t\t<li>Street address is missing.</li>\n\t\t\t\t<li>Postal code is missing.</li>\n\t\t\t\t<li>Phone number is missing.</li>\n\t\t\t</ul>\n\t\t\t<p>This is a pretty big notification for something of some significance. Here is an even longer message that is sure to reach a second line if you believe in yourself.</p>\n\t\t</Banner>\n\t\t<Banner\n\t\t\tstatus=\"critical\"\n\t\t\ttitle=\"Unable to process your request\"\n\t\t>\n\t\t\t<p>More information is needed in order to process your request.</p>\n\t\t\t<ul>\n\t\t\t\t<li>Street address is missing.</li>\n\t\t\t\t<li>Postal code is missing.</li>\n\t\t\t\t<li>Phone number is missing.</li>\n\t\t\t</ul>\n\t\t</Banner>\n\t</Stack>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "more-examples",
    style: {
      position: "relative"
    }
  }, "More Examples", React.createElement(_components.a, {
    href: "#more-examples",
    "aria-label": "more examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Banner\n\t\ticon\n\t\tonClose={() => {}}\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"Do Everything\"\n\t\tonSecondaryActionClick={() => {}}\n\t\tsecondaryActionName=\"Do Nothing\"\n\t\ttitle=\"Message sent.\"\n\t>\n\t\tThis is a pretty big notification for something of some significance.\n\t</Banner>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Banner\n\t\tstatus=\"success\"\n\t\ticon\n\t\tonClose={() => {}}\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"Hello\"\n\t\tsecondaryActionName=\"Goodbye\"\n\t\tonSecondaryActionClick={() => {}}\n\t\ttitle=\"Message sent.\"\n\t/>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Banner\n\t\tstatus=\"warning\"\n\t\ticon\n\t\tonClose={() => {}}\n\t\ttitle=\"Message sent.\"\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"Settings Page\"\n\t>\n\t\tThis is a pretty big notification for something of some significance.\n\t</Banner>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Stack direction=\"column\" spacing=\"3\">\n\t\t<Banner title=\"Something in a confined area also works for the banner.\"/>\n\t\t<Banner\n\t\t\tstatus=\"critical\"\n\t\t\ticon\n\t\t\tonClose={() => {}}\n\t\t\ttitle=\"Message sent.\"\n\t\t\tonPrimaryActionClick={() => {}}\n\t\t\tprimaryActionName=\"Retry\"\n\t\t>\n\t\t\t<p>This is a pretty big notification for something of some significance. Here is an even longer message that is sure to reach a second line if you believe in yourself.</p>\n\t\t\t<p>Here are some other things you’re being warned about.</p>\n\t\t\t<ul>\n\t\t\t\t<li>Hey look, it’s a list item.</li>\n\t\t\t\t<li>Another warning about something.</li>\n\t\t\t\t<li>Always choose C</li>\n\t\t\t\t<li>One more for the fans.</li>\n\t\t\t</ul>\n\t\t</Banner>\n\t</Stack>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Avoid having the line length of the banner exceeding 100 characters."), "\n", React.createElement(_components.li, null, "Banners should be placed close to their context."), "\n", React.createElement(_components.li, null, "Be concise and scannable with messages."), "\n", React.createElement(_components.li, null, "Don't use on a regular basis to display primary information or actions."), "\n", React.createElement(_components.li, null, "Use warning and critical statuses sparingly."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-components",
    style: {
      position: "relative"
    }
  }, "Related Components", React.createElement(_components.a, {
    href: "#related-components",
    "aria-label": "related components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "To require a user interaction isolated from the page content, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/modal/"
  }, "Modal")), " component."), "\n", React.createElement(_components.li, null, "For critical information that requires a specific decision, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/dialog"
  }, "Dialog")), " component."), "\n"), "\n", React.createElement(_components.p, null, "For non-intrusive notifications:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Site-wide information about an event, use the  ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/announcement"
  }, "Announcement")), " notification."), "\n", React.createElement(_components.li, null, "Quick, successful information, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/snackbar/"
  }, "Snackbar")), " notification."), "\n", React.createElement(_components.li, null, "Persistent, detailed feedback, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/toast/"
  }, "Toast")), " notification."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Banner } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
